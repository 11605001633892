import { Canvas } from "@react-three/fiber";
import { SceneContainer } from "./components/SceneContainer";

function App() {
    return (
        <Canvas>
            <SceneContainer />
        </Canvas>
    );
}

export default App;
